<template lang="pug">
    div
        loader(v-if="$root.loader")
        template(v-else)
            header-component
                v-btn.no-margin(absolute dark fab top right color='primary' @click.stop="drawerRight = !drawerRight")
                    v-icon search
            v-navigation-drawer.filters.grey.darken-3(fixed v-model="drawerRight" right app width="246")
                v-form
                    v-toolbar.grey.darken-1(flat)
                        v-text-field(v-model="filter.query" :placeholder="$t('Buscar...')" dark)
                        v-icon(dark) search
                    .filters-content
                        .white--text.mb-2 {{$t('Filtrar por')}}:
                        v-select.mb-3(
                            v-if="isadmin"
                            v-model="filter.network"
                            :items='networkNames'
                            item-text="name"
                            item-value="uuid"
                            :label='$t("Red")'
                            append-icon="keyboard_arrow_down" 
                            autocomplete
                            :filter="vFilter"
                            dark
                            color="white"
                        )
                        v-dialog.mb-3(ref='dialogStart' v-model='modalDateStart' lazy full-width width='290px' :return-value.sync='filter.from')
                            v-text-field(slot='activator' :label='$t("Desde")' v-model='filter.from' readonly dark color="white")
                            v-date-picker(:locale="$root.currentLanguage" v-model='filter.from' scrollable)
                                v-spacer
                                v-btn(flat color='primary' @click.stop='modalDateStart = false') {{ $t('Cancelar') }}
                                v-btn(flat color='primary' @click.stop='$refs.dialogStart.save(filter.from)') {{ $t('OK') }}
                        v-dialog.mb-4(ref='dialogEnd' v-model='modalDateEnd' lazy full-width width='290px' :return-value.sync='filter.to')
                            v-text-field(slot='activator' :label='$t("Hasta")' v-model='filter.to' readonly dark color="white")
                            v-date-picker(:locale="$root.currentLanguage" v-model='filter.to' scrollable)
                                v-spacer
                                v-btn(flat color='primary' @click.stop='modalDateEnd = false') {{ $t('Cancelar') }}
                                v-btn(flat color='primary' @click.stop='$refs.dialogEnd.save(filter.to)') {{ $t('OK') }}
                        .btns.btns-right
                            v-btn(@click="cleanFilter" color="primary") {{ $t('Limpiar') }}
            v-content
                v-snackbar(
                :timeout="3000",
                :top="true"
                :right="true"
                :color="bannerColor"
                v-model="bannerMsj")
                    | {{ bannerText }}
                    v-btn(flat, dark, @click.native="bannerMsj = false") {{ $t('cerrar') }}
                v-container.grid-list-xl(fluid)
                    h1 {{ $t('Causas') }}
                    template(v-if="preload")
                        h3 {{ $t('Cargando')}}...
                    template(v-else)
                        v-layout(row wrap v-if="banner.length")
                            v-flex(xs12 sm6 md4 lg3 xl2 v-for="banner in banner" :key="banner.uuid")
                                .sheet.grey.lighten-3
                                    .sheet-options
                                        v-menu(left)
                                            v-icon(color='primary' dark slot='activator') more_vert
                                            v-list.sheet-menu
                                                v-list-tile.white--text(v-if="isadmin || isbanner" @click="open('edit', $t('Editar causa'), banner)")
                                                    v-list-tile-title {{ $t('Editar') }}
                                                v-list-tile.white--text(v-if="isadmin || isbanner" @click="$refs.delete.open(banner)")
                                                    v-list-tile-title {{ $t('Eliminar') }}
                                    .sheet-media
                                        img(:src="imgurl + banner.image + '?time=' + banner.time")
                                    .sheet-title
                                        | {{ banner.network.name }}
                                    .sheet-content
                                        p {{ banner.url }}
                            v-flex(xs12)
                                infinite-loading(:infinite="infinite" ref="infinite")
                        .not-found(v-else)
                            h3 {{ $t('No se encontraron imágenes') }}
                    v-btn(v-if="isadmin || isbanner" @click.native="open('add', $t('Agregar causa'))" fixed dark fab bottom right color='primary')
                        v-icon add
                    v-dialog(v-model="bannerModal" max-width="600")
                        v-form
                            v-card
                                v-card-title {{ $t('Crear o editar causa') }}
                                v-card-text
                                    v-layout(row wrap)
                                        v-flex(xs12)
                                            v-text-field(label="Enlace" v-model="bannerModel.url")
                                        v-flex(xs12)
                                            h3.mb-3 {{ $t('Banner') }}
                                            v-btn.btn-file(color="success" outline block large) 
                                                v-icon(left) file_upload
                                                | {{ $t('Subir foto') }}
                                                input(type='file' @change='fileChange($event, "imageImg", "image")')
                                            .img-file(v-if="bannerModel.imageImg.length")
                                                img(:src="bannerModel.imageImg")
                                                v-icon(@click='removeImage("imageImg", "image")') close
                                            .img-file(v-else-if="bannerModel.image")
                                                img(:src="imgurl + bannerModel.image + '?time=' + bannerModel.time")
                                        v-flex(xs12 v-if="isadmin")
                                            v-select.mb-3(
                                                v-model="bannerModel.networkid"
                                                :items='networkNames'
                                                item-text="name"
                                                item-value="uuid"
                                                :label='$t("Red")'
                                                append-icon="keyboard_arrow_down" 
                                                autocomplete
                                                :filter="vFilter"
                                                @input="bannerModel.users=[]"
                                            )
                                        v-flex(xs12 v-if="isadmin")
                                            v-select.mb-3(
                                                :loading="loading"
                                                v-model="bannerModel.users"
                                                :items='users'
                                                item-text="email"
                                                item-value="uuid"
                                                :label='$t("Usuarios")'
                                                append-icon="keyboard_arrow_down" 
                                                autocomplete
                                                :filter="vFilterEmail"
                                                cache-items
                                                :search-input.sync="search"
                                                multiple
                                                chips
                                                deletable-chips
                                            )
                                    v-layout(row wrap)
                                        v-flex(xs12)
                                            h3.mb-3 {{$t('Usuarios excluidos')}}
                                        v-flex(xs12)
                                            v-select.mb-3(
                                                :loading="loading2"
                                                v-model="bannerModel.excludes"
                                                :items='users2'
                                                item-text="email"
                                                item-value="uuid"
                                                :label='$t("Usuarios excluidos")'
                                                append-icon="keyboard_arrow_down" 
                                                autocomplete
                                                :filter="vFilterEmail"
                                                :search-input.sync="search2"
                                                chips
                                                multiple
                                                deletable-chips
                                            )
                                    v-layout(row wrap)
                                        v-flex(xs12)
                                            h3.mb-3 {{$t('Grupo de usuarios')}}
                                        v-flex(xs12 sm6)
                                            v-select.mb-3(
                                                v-model="modelRule.country"
                                                :items='countries'
                                                item-text="name"
                                                item-value="uuid"
                                                :label='$t("País")'
                                                append-icon="keyboard_arrow_down" 
                                                autocomplete
                                                :filter="vFilter"
                                                :return-object="true"
                                                @input="modelRule.state={},modelRule.city={},states=[],cities=[],fetchState()"
                                            )
                                        v-flex(xs12 sm6)
                                            v-select.mb-3(
                                                v-model="modelRule.state"
                                                :items='states'
                                                item-text="name"
                                                item-value="uuid"
                                                :label='$t("Estado/Departamento")'
                                                append-icon="keyboard_arrow_down" 
                                                autocomplete
                                                :filter="vFilter"
                                                :return-object="true"
                                                @input="modelRule.city={},cities=[],fetchCity()"
                                            )
                                        v-flex(xs12 sm6)
                                            v-select.mb-3(
                                                v-model="modelRule.city"
                                                :items='cities'
                                                item-text="name"
                                                item-value="uuid"
                                                :label='$t("Ciudad")'
                                                append-icon="keyboard_arrow_down" 
                                                autocomplete
                                                :filter="vFilter"
                                                :return-object="true"
                                            )
                                        v-flex(xs12 sm6)
                                            v-select(
                                                v-model="modelRule.gender"
                                                :items='gender'
                                                item-text="text"
                                                item-value="id"
                                                :label='$t("Género")'
                                                append-icon="keyboard_arrow_down" 
                                                autocomplete
                                                :return-object="true"
                                                :filter="vFilter"
                                            )
                                        v-flex(xs12 sm6)
                                            v-select(
                                                v-model="modelRule.age"
                                                :items='ages'
                                                item-text="text"
                                                item-value="id"
                                                :label='$t("Rango de edad")'
                                                append-icon="keyboard_arrow_down" 
                                                autocomplete
                                                :return-object="true"
                                                :filter="vFilter"
                                            )
                                        v-flex(xs12)
                                            .btns.btns-right
                                                v-btn(color="secondary" small @click="addRule") {{ $t('Agregar condición') }}
                                        v-flex.network_rule(xs12)
                                            v-list
                                                v-list-tile(v-for="(rule, i) in modelRule.rules" :key="'networkr'+i")
                                                    v-list-tile-content 
                                                        small
                                                            template(v-if="rule.country && Object.keys(rule.country).length") {{ $t('País') }}: {{ rule.country.name }}&nbsp;
                                                            template(v-if="rule.state && Object.keys(rule.state).length") {{ $t('Estado/departamento') }}: {{ rule.state.name }}&nbsp;
                                                            template(v-if="rule.city && Object.keys(rule.city).length") {{ $t('Ciudad') }}: {{ rule.city.name }}&nbsp;
                                                            template(v-if="rule.gender && Object.keys(rule.gender).length") {{ $t('Género') }}: {{ rule.gender.text }}&nbsp;
                                                            template(v-if="rule.age && Object.keys(rule.age).length") {{ $t('Rango de edad') }}: {{ rule.age.text }}&nbsp;

                                                    v-list-tile-action
                                                        v-icon(@click="modelRule.rules.splice(i, 1)") delete
                                v-card-actions
                                    .btns.btns-right
                                        v-btn(color="secondary" small @click="bannerModal = false") {{ $t('Cancelar') }}
                                        v-btn(:loading="loadingBtn" color="primary" large @click.stop="save") {{ $t('Guardar') }}
            confirm(ref="delete" @action="deleteBanner" :title="$t('Eliminar causa')" :message="$t('¿Estás seguro de eliminar esta causa?')")
</template>

<script>

    import auth from 'mixins/auth'
    import config from 'src/config'
    
    export default {
        mixins: [auth], 
        metaInfo() {
            return {
                title: this.$t('Causas')
            }
        },
        data() {
            return {
                loading: false,
                loading2: false,
                preload: false,
                pagination: {},
                drawerRight: false,
                modalDateStart: false,
                modalDateEnd: false,
                filter: {},
                filterSchema: {
                    query    : '',
                    network  : '',
                    from     : null,
                    to       : null
                },
                banner      : [],
                networkNames : [],
                loadingBtn: false,
                bannerSchema: {
                    uuid       : '',
                    networkid  : '',
                    imageImg   : '',
                    image      : '',
                    url        : '',
                    users      : [],
                    rules_json : [],
                    excludes   : []
                },
                bannerModel: {},
                bannerMsj  : false,
                bannerColor: '',
                bannerText : '',
                bannerModal: false,
                limit : this.$root.limit,
                offset: 0,
                queryUser: '',
                users: [],
                users2: [],
                search: null,
                search2: null,
                modelRuleSchema: {
                    network  : {},
                    country  : {},
                    state    : {},
                    city     : {},
                    gender   : {},
                    age      : {},
                    rules    : []
                },
                modelRule: {},
                countries  : [],
                states     : [],
                cities     : []
            }
        },
        computed: {
            profile(){
                return this.$root.profile
            },
            imgurl(){
                return config.uploadbanner
            }
        },
        watch: {
            filter: {
                handler(){
                    this.fetchBanner()
                },
                deep: true
            },
            profile: {
                handler(){
                    this.fetchNetworkNames()
                },
                deep: true
            },
            search(val){
                val && this.searchUsers(val)
            },
            search2(val){
                val && this.searchUsers2(val)
            }
        },
        created(){
            
            this.fetchBanner()
            this.bannerModel = Object.assign({}, this.bannerSchema)
            this.filter = Object.assign({}, this.filterSchema)
            this.modelRule   = this._.cloneDeep(this.modelRuleSchema)
        },
        methods: {
            
            fetchBanner(infinite = false){

                if(infinite){
                    this.offset += this.limit
                }else{
                    this.preload = true
                    this.banner = []
                    this.offset = 0
                    this.$refs.infinite && this.$refs.infinite.reset()
                }

                let filter = {
                    limit : this.limit,
                    offset: this.offset,
                    filter: JSON.stringify(this.filter)
                }

                this.$api(this, (xhr) => {
                    xhr.get('/banner', { params: filter }).then((r) => {

                        if(!infinite) this.preload = false
                        let data = r.data

                        if(infinite){
                            if(data.response){
                                this.banner = this.banner.concat(data.data);
                                this.$refs.infinite && this.$refs.infinite.loaded()
                            }else{
                                this.$refs.infinite && this.$refs.infinite.complete()
                            }
                        }else{
                            this.banner = (data.response ? data.data : [])
                        }
                    }).catch(() => {})
                })
            },

            infinite(){
                setTimeout(() => this.fetchBanner(true) , 500)
            },

            fetchNetworkNames(){

                let url = '/network?list=1'

                if(this.$root.profile.type == 2){
                    url = '/network/profile?list=1'
                }

                this.$api(this, (xhr) => {
                    xhr.get(url).then((r) => {

                        let data = r.data
                        if(data.response){
                            let networkNames = [[]].concat(data.data) 
                            this.networkNames = networkNames
                        }
                    }).catch(() => {})
                })
            },

            cleanFilter(){
                this.filter = Object.assign({}, this.filterSchema)
            },

            async open(action, title, data = this.bannerSchema){

                this.titleBanner  = title
                this.updateBanner = (action == 'add' ? false: true)

                this.bannerModel = this._.cloneDeep(this.bannerSchema)
                this.bannerModel = Object.assign({}, this.bannerModel, data)
                this.bannerModel.image  = this.bannerModel.image == null ? '': this.bannerModel.image
                
                if(this.bannerModel.users.length){
                    this.users = this.users.concat(this.bannerModel.users)
                }

                await this.fetchCountries()

                let rules = this.bannerModel.rules_json.map((r => {

                    if('gender' in r){
                        r.gender = this.gender.find(g => g.id == r.gender)
                    }

                    if('age' in r){
                        r.age = this.ages.find(a => a.id == r.age)
                    }

                    return r
                }))

                if(rules.length){
                    this.modelRule.rules = rules
                }

                this.loadingBtn = false
                this.bannerModal  = true
            },

            save(){

                if(!(this.isadmin || this.isbanner)){
                    return
                }

                let post   = Object.assign({}, this.bannerModel)
                post.url   = post.url == null ? '' : post.url

                const users = function(user){
                    if(user instanceof Object){
                        return user.uuid
                    }else{
                        return user
                    }
                }

                post.users = post.users.map(users)
                post.users = JSON.stringify(post.users)

                post.excludes = post.excludes.map(users)
                post.excludes = JSON.stringify(post.excludes)

                let rules = this.modelRule.rules.map((n) => {
                    return {
                        country: !n.hasOwnProperty('country') ? '' : (typeof n.country == 'object' ? (Object.keys(n.country).length ? n.country.uuid : '') : ''),
                        state  : !n.hasOwnProperty('state') ? '' : (typeof n.state == 'object' ? (Object.keys(n.state).length ? n.state.uuid : '') : ''),
                        city   : !n.hasOwnProperty('city') ? '' : (typeof n.city == 'object' ? (Object.keys(n.city).length ? n.city.uuid : '') : ''),
                        gender : !n.hasOwnProperty('gender') ? '' : (typeof n.gender == 'object' ? (Object.keys(n.gender).length ? n.gender.id : '') : ''),
                        age    : !n.hasOwnProperty('age') ? '' : (typeof n.age == 'object' ? (Object.keys(n.age).length ? n.age.id : '') : ''),
                    }
                })

                post.rules_json = JSON.stringify(rules)

                let form = new FormData()

                for(let i in post){
                    form.append(i, post[i])
                }

                this.loadingBtn = true
                this.$api(this, (xhr) => {
                    xhr.post('/banner', form).then((r) => {

                        let data = r.data
                        this.loadingBtn = false
                        this.bannerMsj = true

                        if(data.response){
                            this.bannerColor = 'success'
                            this.bannerText = data.message
                            this.bannerModal = false
                            this.fetchBanner()
                        }else{
                            this.bannerColor = 'error'
                            this.bannerText = data.message
                        }
                    }).catch(() => {})
                })
            },

            fileChange(e, model, save) {

                var files = e.target.files || e.dataTransfer.files
                if (!files.length) return
                this.createImage(files[0], model, save)
            },
            createImage(file, model, save) {

                let reader = new FileReader();
                reader.onload = (e) => this.bannerModel[model] = e.target.result
                reader.readAsDataURL(file)
                this.bannerModel[save] = file
            },
            removeImage(model, save) {
                this.bannerModel[model] = ''
                this.bannerModel[save] = ''
            },
            deleteBanner({uuid}){

                this.$refs.delete.$emit('playLoader')
                this.$api(this, (xhr) => {
                    xhr.delete('/banner?uuid=' + uuid).then((r) => {
                        
                        this.$refs.delete.$emit('stopLoader')
                        this.bannerMsj = true

                        let data = r.data

                        if(data.response){
                            this.bannerColor = 'success'
                            this.bannerText = data.message
                            this.$refs.delete.close()
                            this.fetchBanner()
                        }else{
                            this.bannerColor = 'error'
                            this.bannerText = data.message
                        }
                    }).catch(() => {})
                })
            },
            searchUsers(query){

                this.loading = true
                let url = '/network/users'

                this.$api(this, (xhr) => {
                    xhr.get(url, {
                        params: {
                            query,
                            networkid: this.bannerModel.networkid
                        }
                    }).then((r) => {

                        this.loading = false
                        let data = r.data
                        if(data.response){
                            this.users = data.data
                        }
                    }).catch(() => {
                        this.loading = false
                    })
                })
            },
            searchUsers2(query){

                this.loading2 = true
                let url = '/network/users'

                this.$api(this, (xhr) => {
                    xhr.get(url, {
                        params: {
                            query,
                            networkid: this.bannerModel.networkid
                        }
                    }).then((r) => {

                        this.loading2 = false
                        let data = r.data
                        if(data.response){
                            this.users2 = data.data
                        }
                    }).catch(() => {
                        this.loading2 = false
                    })
                })
            },
            fetchCountries(){

                this.countries = []
                return new Promise((resolve) => {
                    this.$api(this, (xhr) => {
                        xhr.get('/country', {
                            params: {
                                filter: JSON.stringify({
                                    status: 1
                                })
                            }
                        }).then((r) => {

                            let data = r.data
                            if(data.response){
                                this.countries = data.data
                            }

                            resolve()

                        }).catch(() => {
                            resolve()
                        })
                    })
                })
            },
            fetchState(){

                this.states = []
                return new Promise((resolve) => {
                    this.$api(this, (xhr) => {
                        xhr.get('/state', {
                            params: {
                                countryid: this.modelRule.country.uuid,
                                filter: JSON.stringify({
                                    status: 1
                                })
                            }
                        }).then((r) => {

                            let data = r.data
                            if(data.response){
                                this.states = data.data
                            }
                            resolve()
                        }).catch(() => {
                            resolve()
                        })
                    })
                })
            },
            fetchCity(){

                this.cities = []
                return new Promise((resolve) => {
                    this.$api(this, (xhr) => {
                        xhr.get('/city', {
                            params: {
                                stateid: this.modelRule.state.uuid,
                                filter: JSON.stringify({
                                    status: 1
                                })
                            }
                        }).then((r) => {

                            let data = r.data
                            if(data.response){
                                this.cities = data.data
                            }
                            resolve()
                        }).catch(() => {
                            resolve()
                        })
                    })
                })
            },
            addRule(){

                let emptyProps = 0
                for(let key of Object.keys(this.modelRule)){
                    if(key != 'rules' && !Object.keys(this.modelRule[key]).length){
                        emptyProps++
                    }
                }

                if(emptyProps == Object.keys(this.modelRule).length-1){
                    return
                }

                this.modelRule.rules.push({
                    country: this.modelRule.country,
                    state  : this.modelRule.state,
                    city   : this.modelRule.city,
                    gender : this.modelRule.gender,
                    age    : this.modelRule.age
                })

                let rules      = this.modelRule.rules.slice(0)
                this.modelRule = this._.cloneDeep(this.modelRuleSchema)
                this.modelRule.rules = rules
            }
        }
    }
    
</script>